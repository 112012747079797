import { Channel_Types } from '@10x/foundation/types/graphql-schema';

import { RulesSVG } from '@foundationPathAlias/components/svg';

import { HashtagIcon } from '@heroicons/react/20/solid';

const iconsRegistry: any = {
  [Channel_Types.Messages]: HashtagIcon,
  [Channel_Types.Rules]: RulesSVG,
};

export function getChannelIconByType(channelType: Channel_Types) {
  return iconsRegistry[channelType];
}
